import React from 'react';

type HeaderProps = {
  title: string;
  cta?: string;
};

const Header: React.FC<HeaderProps> = ({ title, cta }) => {
  return (
    <div className='flex flex-col gap-6'>
      <h2 className="typography-product-heading-mobile text-color-text-brand dark:text-color-text-brand">
        {title}
      </h2>
      {!!cta && (
        <p className="typography-product-subheading text-color-text-lightmode-primary dark:text-color-text-darkmode-primary pb-6">
          {cta}
        </p>
      )}
    </div>
  );
};

export default Header;
