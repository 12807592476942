import React, { forwardRef, useEffect, useState } from 'react';
import { LocationType, VenueAddress } from 'utils/shared/coachBuilder';
import { addOneHour } from 'utils/shared/date/addHourToSlot';
import { useViewer } from 'hooks/useViewer';
import CalendarDatePicker from 'components/CalendarDatePicker';
import CreateAccount from './CreateAccount';
import Login from './Login';
import PaymentForm from './PaymentForm';
import ResultForm from './ResultForm';
import { Coach, CurrentDateTimeState, CurrentVenueCourtState, Lessons, Steps } from '../types';

interface LessonSchedulerProps {
  coach: Coach;
  lessons?: Lessons;
  fromCalendar?: boolean;
  venueFromMap?: VenueAddress | null;
  initialCurrentDateTime?: CurrentDateTimeState;
  initialSelectedCourtVenue?: CurrentVenueCourtState;
  initialBookedLessonId?: string;
  setVenueFromMap?: React.Dispatch<React.SetStateAction<VenueAddress | null>>;
  handleBookAnotherLesson?: () => void;
  onPaymentSuccess?: () => void;
}

const LessonScheduler = ({
  coach,
  lessons,
  venueFromMap,
  setVenueFromMap,
  fromCalendar = false,
  initialCurrentDateTime,
  initialSelectedCourtVenue,
  initialBookedLessonId,
  handleBookAnotherLesson,
  onPaymentSuccess,
}: LessonSchedulerProps) => {
  const viewer = useViewer();

  const [steps, setSteps] = useState<Steps>(
    fromCalendar
      ? viewer.isAnonymousSession
        ? Steps.CreateAccount
        : Steps.PaymentForm
      : Steps.SelectTime,
  );
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [bookedLessonId, setBookedLessonId] = useState<string | null>(null);
  const [currentDateTime, setCurrentDateTime] = useState<CurrentDateTimeState>({
    date: new Date(),
    startTime: '',
    endTime: '',
  });

  const [selectedCourtVenue, setSelectedCourtVenue] = useState<CurrentVenueCourtState>({
    id: '',
    title: '',
    address: '',
    locationType: LocationType.Venue,
  });

  const handleLessonSchedule = (
    day: Date,
    timeslot: string,
    courtVenue: CurrentVenueCourtState | null,
  ) => {
    if (viewer.isAnonymousSession) {
      setSteps(Steps.CreateAccount);
    } else {
      setSteps(Steps.PaymentForm);
    }

    setCurrentDateTime((prevState) => ({
      ...prevState,
      date: day,
      startTime: timeslot,
      endTime: addOneHour(timeslot),
    }));

    setSelectedCourtVenue((prevState) => ({
      ...prevState,
      id: courtVenue?.id || '',
      title: courtVenue?.title || '',
      address: courtVenue?.address || '',
      locationType: courtVenue?.locationType!,
    }));
  };

  useEffect(() => {
    if (!venueFromMap) return;
    setSteps(Steps.SelectTime);
  }, [venueFromMap]);

  useEffect(() => {
    setCurrentDateTime({
      ...currentDateTime,
      date: selectedDate,
    });
  }, [selectedDate]);

  useEffect(() => {
    if (
      fromCalendar &&
      initialCurrentDateTime &&
      initialSelectedCourtVenue &&
      initialBookedLessonId
    ) {
      setCurrentDateTime(initialCurrentDateTime);
      setSelectedCourtVenue(initialSelectedCourtVenue);
      setBookedLessonId(initialBookedLessonId);
    }
  }, [fromCalendar, initialCurrentDateTime, initialSelectedCourtVenue, initialBookedLessonId]);

  return (
    <div className="rounded-xl border border-color-border-input-lightmode bg-color-bg-lightmode-primary shadow-lightmode-primary dark:border-color-border-input-darkmode dark:bg-color-bg-darkmode-primary">
      {fromCalendar === false && steps === Steps.SelectTime && (
        <CalendarDatePicker
          selectTimeSlot={handleLessonSchedule}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          coach={coach}
          lessons={lessons || []}
          venueFromMap={venueFromMap}
          setVenueFromMap={setVenueFromMap}
        />
      )}
      {steps === Steps.CreateAccount && (
        <CreateAccount
          setSteps={setSteps}
          handleNext={() => {
            setSteps(Steps.PaymentForm);
          }}
        />
      )}
      {steps === Steps.Login && (
        <Login
          setSteps={setSteps}
          handleNext={() => {
            setSteps(Steps.PaymentForm);
          }}
        />
      )}
      {steps === Steps.PaymentForm && (
        <PaymentForm
          setBookedLessonId={setBookedLessonId}
          setSteps={setSteps}
          currentDateTime={currentDateTime}
          selectedCourtVenue={selectedCourtVenue}
          coach={coach}
          allowBack={!fromCalendar}
          onPaymentSuccess={onPaymentSuccess}
        />
      )}
      {steps === Steps.ResultForm && (
        <ResultForm
          bookedLessonId={bookedLessonId}
          setSteps={setSteps}
          currentDateTime={currentDateTime}
          coach={coach}
          selectedCourtVenue={selectedCourtVenue}
          handleBookAnotherLesson={handleBookAnotherLesson}
        />
      )}
    </div>
  );
};

export default LessonScheduler;
