import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useRouter } from 'next/router';
import toast from 'react-hot-toast';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { COACH_PAYMENT_API, PostRequestPayload } from 'constants/payloads/lessonsBook';
import { CoachStatusEnum } from 'types/generated/client';
import { useUpdatePlayerPhoneMutation } from 'types/generated/client';
import { Response, getSetupIntent } from 'services/client/stripe/getSetupIntent';
import { LocationType } from 'utils/shared/coachBuilder';
import { convertToISOStrings, getDateOnly } from 'utils/shared/date/addHourToSlot';
import { convertUnitPriceToFormattedPrice } from 'utils/shared/money/convertUnitPriceToFormattedPrice';
import { getLessonItemizedTotal } from 'utils/shared/money/getLessonItemizedTotal';
import { toDigits } from 'utils/shared/phone/toDigits';
import { toFormattedPhone } from 'utils/shared/phone/toFormatedPhone';
import { toSafePhone } from 'utils/shared/phone/toSafePhone';
import { getNavigatorLanguage } from 'utils/shared/time/getNavigatorLanguage';
import { getTimezone } from 'utils/shared/time/getTimezone';
import { useApiGateway } from 'hooks/useApi';
import { useGetCurrentUser } from 'hooks/useGetCurrentUser';
import { useMobileView } from 'hooks/useMobileView';
import { useStripe as useStripe1 } from 'hooks/useStripe';
import { useViewer } from 'hooks/useViewer';
import ArrowLeft from 'svg/ArrowLeft';
import Button from 'components/Button';
import LoadingSkeleton from 'components/LoadingSkeleton';
import classNames from 'styles/utils/classNames';
import BankAccountPrompt from '../BankAccountPrompt';
import { CheckoutFormProps, PaymentFormProps, Steps } from '../types';

const useCoachPayment = () => {
  const { userId } = useViewer();
  const router = useRouter();
  const [stripeKeys, setStripeKeys] = React.useState<undefined | Response>(undefined);
  const { stripe } = useStripe1();

  const fetchSetupIntent = async () => {
    try {
      const response = await getSetupIntent();
      setStripeKeys(response);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (router.isReady && userId) {
      fetchSetupIntent();
    }
  }, [userId, router.isReady]);

  return {
    stripeKeys,
    stripe,
    fetchSetupIntent,
  };
};

const OrderTotal = ({
  setSteps,
  coach,
  currentDateTime,
  selectedCourtVenue,
  allowBack,
}: Omit<CheckoutFormProps, 'onSubmit' | 'setBookedLessonId'>) => {
  const coachPrice = coach?.priceUnitAmountCoachDefault ?? 0;
  const params = {
    priceUnitAmount: coachPrice,
  };
  const Prices = getLessonItemizedTotal(params);
  return (
    <>
      <div className="flex items-center gap-4 px-6 pt-6">
        {allowBack && (
          <Button
            variant="inverted"
            className="border-none"
            isInline
            iconLeft={
              <ArrowLeft className="h-4 w-4 xs:text-color-text-lightmode-primary lg:text-color-text-brand" />
            }
            onClick={() => {
              setSteps?.(Steps.SelectTime);
            }}
          />
        )}
        <p className="typography-product-heading-compact xs:text-color-text-lightmode-primary lg:text-color-text-brand">
          Pay and confirm your lesson
        </p>
      </div>
      <div className="h-auto px-6 py-5">
        <div className="typography-product-body-highlight rounded-lg border border-color-border-brand bg-color-bg-lightmode-brand-secondary p-4 dark:bg-color-bg-darkmode-brand-secondary">
          <div className="mb-2 flex text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
            <p>{Prices?.priceUnitAmount === 0 ? 'Booking Fee' : 'Lesson'}</p>
            <p className="ml-auto">
              {
                convertUnitPriceToFormattedPrice(Prices?.orderSubtotalWithPlatformFeesUnitAmount)
                  .priceDisplay
              }
            </p>
          </div>
          <div className="text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
            <p className="mb-2 ">
              {`${currentDateTime.date.toDateString()} at ${currentDateTime.startTime}`}{' '}
              {`${selectedCourtVenue?.title ? '@ ' + selectedCourtVenue?.title : ''}`}
            </p>
            <p className="mb-2 mt-5">{selectedCourtVenue?.address}</p>
          </div>
          {Prices.coachAmountReceived !== 0 && (
            <div className="mb-4 flex items-center">
              <p>Taxes and fees</p>
              <p className="ml-auto">
                {convertUnitPriceToFormattedPrice(Prices?.creditCardFee).priceDisplayFull}
              </p>
            </div>
          )}
          <div className="typography-product-subheading flex text-color-text-brand">
            <p>Total</p>
            <p className="ml-auto">
              {convertUnitPriceToFormattedPrice(Prices?.orderTotal).priceDisplay}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const LoadingSkeletonCollection = () => {
  return (
    <div className="space-y-4">
      <div>
        <LoadingSkeleton count={1} />
        <LoadingSkeleton height="3rem" />
      </div>
      <div>
        <LoadingSkeleton count={1} />
        <LoadingSkeleton height="3rem" />
      </div>
      <div>
        <LoadingSkeleton count={1} />
        <LoadingSkeleton height="3rem" />
      </div>
    </div>
  );
};

export const CheckoutForm = ({
  onSubmit,
  setSteps,
  coach,
  currentDateTime,
  phoneExists = '',
  setBookedLessonId,
  selectedCourtVenue,
}: CheckoutFormProps) => {
  const timezoneName = getTimezone();
  const currentDate = new Date();
  const timezoneOffsetMinutes = Math.round(currentDate.getTimezoneOffset());
  const timezoneAbbreviation = currentDate
    .toLocaleString('en', { timeZoneName: 'short' })
    .split(' ')
    .pop();

  const stripe = useStripe();
  const elements = useElements();
  const { userId } = useViewer();
  const { user } = useGetCurrentUser();
  const [updatePlayerPhoneMutation] = useUpdatePlayerPhoneMutation();

  const [isLoading, setIsLoading] = useState(false);
  const [isStripeReady, setIsStripeReady] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [dialCode, setDialCode] = useState('');
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const isMobile = useMobileView();
  const coachPrice = coach?.priceUnitAmountCoachDefault ?? 0;

  const {
    post: createCoachPayment,
    isError: paymentError,
    error: paymentErrorMessage,
  } = useApiGateway<PostRequestPayload>(COACH_PAYMENT_API);

  const params = {
    priceUnitAmount: coachPrice,
  };
  const Prices = getLessonItemizedTotal(params);

  const validatePhoneNumber = (): string | null => {
    if (phoneNumber.trim() === '') {
      return 'Phone number is required.';
    }
    const digitsOnly = phoneNumber.replace(/\D/g, '');
    if (digitsOnly.length < 10 || digitsOnly.length > 15) {
      return 'Phone number must be between 10 and 15 digits including country code.';
    }

    return null;
  };

  const handlePhoneNumberChange = (phone: string, country: any): void => {
    const { dialCode } = country;

    setDialCode(`${toSafePhone(dialCode)}`);

    let inputPhoneNumber: string = toFormattedPhone(phone);
    setPhoneNumber(inputPhoneNumber);
  };

  useEffect(() => {
    if (!elements) {
      Sentry.captureException(
        new Error('Failed to initialize ELEMENTS for Stripe for tournament payment'),
      );
    }
  }, [elements]);

  useEffect(() => {
    if (!stripe) {
      Sentry.captureException(new Error('Failed to initialize STRIPE for tournament payment'));
    }
  }, [stripe]);

  return (
    <form
      className="flex max-h-[calc(100vh-6rem)] flex-col overflow-hidden"
      onSubmit={async (event) => {
        event.preventDefault();

        if (!phoneExists) {
          const phoneError = validatePhoneNumber();
          if (phoneError) {
            setPhoneError(phoneError);
            return;
          }
        }

        if (!stripe || !elements) {
          return;
        }

        setIsLoading(true);

        try {
          const { setupIntent, error } = await stripe.confirmSetup({
            elements,
            redirect: 'if_required',
            confirmParams: { return_url: window.location.href },
          });

          if (error) {
            Sentry.captureException(error);
            toast.error(
              'There was an error signing up for the lesson. Refresh the page and try again.',
            );
            setIsLoading(false);
            return;
          }

          const conversionData = convertToISOStrings(
            getDateOnly(currentDateTime?.date),
            currentDateTime?.startTime,
            currentDateTime?.endTime,
          );

          const response = await createCoachPayment({
            payload: {
              coachId: coach.id,
              paymentMethodId: setupIntent?.payment_method as string,
              amount: Prices?.priceUnitAmount,
              date: getDateOnly(currentDateTime?.date),
              startTime: conversionData.startDateTime,
              endTime: conversionData.endDateTime,
              locale: getNavigatorLanguage(),
              timezoneName: timezoneName,
              timezoneAbbreviation: timezoneAbbreviation || '',
              timezoneOffsetMinutes: timezoneOffsetMinutes || 0,
              venueId:
                selectedCourtVenue?.locationType === LocationType?.Venue
                  ? selectedCourtVenue?.id
                  : '',
              userCustomCourtId:
                selectedCourtVenue?.locationType === LocationType?.Custom
                  ? selectedCourtVenue?.id
                  : '',
              fullAddress: selectedCourtVenue?.address,
            },
          });

          if (response.data) {
            setBookedLessonId?.(response.data.lesson.id);
          }

          if (response?.isError) {
            throw response?.error;
          }

          if (userId && phoneNumber && dialCode) {
            const numberFormatted = toDigits(phoneNumber);
            const playerVariables = {
              id: userId,
              phoneNumber: numberFormatted?.slice(dialCode.length),
              phoneCountryCode: toDigits(dialCode),
            };

            await updatePlayerPhoneMutation({
              variables: playerVariables,
            });
          }

          onSubmit();

          setIsLoading(false);
        } catch (error) {
          Sentry.captureException(error);
          toast.error(
            'There was an error signing up for the lesson. Refresh the page and try again',
          );
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      }}
    >
      <div className="flex flex-col overflow-y-auto">
        <OrderTotal
          setSteps={setSteps}
          coach={coach}
          currentDateTime={currentDateTime}
          selectedCourtVenue={selectedCourtVenue}
        />
        <div className="tournament-register-form flex h-full flex-col items-start px-6">
          <div className="flex h-full w-full flex-col pb-4">
            <div className={classNames(isStripeReady ? 'block' : 'hidden')}>
              <PaymentElement
                onReady={() => setIsStripeReady(true)}
                options={{
                  defaultValues: {},
                  fields: {
                    billingDetails: {
                      name: 'auto',
                    },
                  },
                }}
              />
            </div>
            <div className={classNames(isStripeReady ? 'hidden' : 'block')}>
              <LoadingSkeletonCollection />
            </div>
            {!phoneExists && (
              <>
                <div className="mb-[-1.1rem] mt-3 w-full text-[.93rem]">
                  <label className="font-normal text-brand-gray-75">Phone</label>
                </div>
                <div className="w-full">
                  <PhoneInput
                    onBlur={() => setPhoneError(validatePhoneNumber())}
                    onFocus={() => setPhoneError('')}
                    inputProps={{
                      name: 'phone',
                      required: true,
                    }}
                    country={'us'}
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    containerClass={'mt-5 '}
                    inputClass={classNames(
                      '!w-full !py-3',
                      phoneError &&
                        'ring !ring-color-bg-darkmode-icon-error dark:!bg-color-bg-darkmode-error',
                    )}
                  />
                  {phoneError && (
                    <p className={'bottom-1 mb-2 mt-1 text-xs text-color-error'}>{phoneError}</p>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="sticky w-full px-6 pb-6">
        <Button
          disabled={!stripe || !elements || isLoading}
          type="submit"
          variant={isMobile ? 'brand' : 'primary'}
          size="lg"
        >
          {isLoading ? 'Loading...' : 'Pay'}
        </Button>
      </div>
    </form>
  );
};

export default function PaymentForm({
  setSteps,
  currentDateTime,
  coach,
  setBookedLessonId,
  selectedCourtVenue,
  onPaymentSuccess,
  allowBack = true,
}: PaymentFormProps) {
  const { stripeKeys, stripe } = useCoachPayment();
  const { user } = useGetCurrentUser();
  const userId = user?.id;
  const coachId = coach?.id;
  const isCoach = user?.coachStatus === CoachStatusEnum.Active;

  return (
    <>
      {userId !== coachId && (
        <>
          {stripeKeys?.setupIntentClientSecret ? (
            <Elements
              stripe={stripe}
              options={{
                clientSecret: stripeKeys?.setupIntentClientSecret,
                appearance: {
                  labels: undefined,
                },
              }}
            >
              <CheckoutForm
                setSteps={setSteps}
                onSubmit={() => {
                  onPaymentSuccess?.();
                  setSteps(Steps.ResultForm);
                }}
                coach={coach}
                currentDateTime={currentDateTime}
                selectedCourtVenue={selectedCourtVenue}
                phoneExists={user?.phoneNumber || ''}
                setBookedLessonId={setBookedLessonId}
                allowBack={allowBack}
              />
            </Elements>
          ) : (
            <div>
              <OrderTotal
                setSteps={setSteps}
                coach={coach}
                currentDateTime={currentDateTime}
                phoneExists={user?.phoneNumber || ''}
                selectedCourtVenue={selectedCourtVenue}
              />
              <div className="px-6 pb-6">
                <LoadingSkeletonCollection />
              </div>
            </div>
          )}
        </>
      )}
      {isCoach && userId === coachId && (
        <BankAccountPrompt
          heading={'This is your page!'}
          description={`Unfortunately, you can't book a lesson with yourself. Share your page so others can take advantage of your lessons today!`}
          showButton={false}
        />
      )}
    </>
  );
}
