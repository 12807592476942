import React, { useEffect } from 'react';
import { format } from 'date-fns';
import Link from 'next/link';
import { MY_YOU_PAGE } from 'constants/pages';
import { useGetLessonByIdLazyQuery } from 'types/generated/client';
import { convertUnitPriceToFormattedPrice } from 'utils/shared/money/convertUnitPriceToFormattedPrice';
import { getLessonItemizedTotal } from 'utils/shared/money/getLessonItemizedTotal';
import Button, { ButtonLink } from 'components/Button';
import { Lesson } from 'components/LessonCalendar/props';
import { calendarLinks, generateCalendarUrl } from 'components/LessonCalendar/utils';
import { ResultFormProps, Steps } from '../types';

export default function ResultForm({
  currentDateTime,
  coach,
  bookedLessonId,
  setSteps,
  handleBookAnotherLesson,
}: ResultFormProps) {
  const coachPrice = coach?.priceUnitAmountCoachDefault ?? 0;

  const [getLessonByIdQuery, { data: lessonData }] = useGetLessonByIdLazyQuery();

  useEffect(() => {
    const init = async () => {
      await getLessonByIdQuery({
        variables: {
          id: bookedLessonId,
        },
      });
    };
    bookedLessonId && init();
  }, [getLessonByIdQuery, bookedLessonId]);

  const params = {
    priceUnitAmount: coachPrice,
  };
  const Prices = getLessonItemizedTotal(params);

  const lesson = lessonData?.lessonsByPk;

  return (
    <div className="flex flex-col px-6 py-5">
      <p className="typography-product-heading-compact xs:text-color-text-lightmode mb-8 lg:text-color-text-brand">
        Lesson booked!
      </p>
      <p className="pb-2 text-left text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
        You’re all set! Here are your lesson details.
      </p>
      <div className="h-auto overflow-y-auto overflow-x-hidden">
        <div className="typography-product-body-highlight mb-8 rounded-[8px] border border-color-border-brand bg-color-bg-lightmode-brand-secondary p-4 dark:bg-color-bg-darkmode-brand-secondary">
          <div className="mb-2 flex text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
            <p className="pb-1 text-left ">Lesson with {coach?.fullName}</p>
          </div>
          <div className="text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
            <p className="pb-8 text-left">
              {`${format(currentDateTime?.date, 'EEEE, MMM d')} at ${currentDateTime.startTime}`}{' '}
              {`${lesson?.venue?.title ? '@ ' + lesson?.venue?.title : ''}`}
            </p>
            <p className="mb-2">
              {lesson?.venue?.addressString || lesson?.userCustomCourt?.fullAddress}
            </p>
          </div>

          <div className="typography-product-subheading flex text-color-text-brand">
            <p>Total Cost</p>
            <p className="ml-auto">
              {convertUnitPriceToFormattedPrice(Prices?.orderTotal).priceDisplay}
            </p>
          </div>
        </div>
      </div>

      {lesson && (
        <>
          <span className="typography-product-subheading text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
            Add to Calendar
          </span>

          <div className="flex  justify-around gap-2 py-5">
            {calendarLinks.map(({ Icon, type, className }, index) => (
              <Link
                href={generateCalendarUrl(type, lesson as Lesson)}
                target="_blank"
                className="cursor-pointer"
                key={index}
              >
                <Icon className={className} />
              </Link>
            ))}
          </div>
        </>
      )}
      <ButtonLink
        href={MY_YOU_PAGE}
        variant="brand"
        size="lg"
        isInline
        className="mb-2 mt-4 w-full"
      >
        View your lesson
      </ButtonLink>
      <Button
        size="lg"
        onClick={() =>
          handleBookAnotherLesson ? handleBookAnotherLesson() : setSteps(Steps.SelectTime)
        }
        className="w-full"
        variant="inverted"
      >
        Book another lesson
      </Button>
    </div>
  );
}
