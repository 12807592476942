import { GetCoachBookingPageQuery as ClientGetCoachBookingPageQuery } from 'types/generated/client';
import { type GetCoachBookingPageQuery, GetCoachStatusAndSlotsQuery } from 'types/generated/server';
import { LocationType, VenueAddress } from 'utils/shared/coachBuilder';
import { useGetCurrentUser } from 'hooks/useGetCurrentUser';

export const PLAY_SESSIONS_ID = 'play-sessions';

export enum Steps {
  CreateAccount = 'CREATE_ACCOUNT',
  Login = 'LOGIN',
  SelectTime = 'SELECT_TIME',
  PaymentForm = 'PAYMENT_FORM',
  ResultForm = 'RESULT_FORM',
}

export interface PageProps {
  coach: GetCoachBookingPageQuery['userProfiles'][0];
  coachStarterProfile: GetCoachBookingPageQuery['coachStarterProfilesByPk'];
  usernameOrId: string;
  jsonLd?: string;
}

export type Coach =
  | Omit<Exclude<GetCoachBookingPageQuery['userProfiles'][0], null | undefined>, '__typename'>
  | ClientGetCoachBookingPageQuery['userProfiles'][0];

interface Faq {
  question: string;
  answer: string;
}

export interface ContentProps {
  coach: Coach;
  coachStarterProfile: GetCoachBookingPageQuery['coachStarterProfilesByPk'];
  faqs?: Faq[];
  coachAvailability?: ClientGetCoachBookingPageQuery['userProfiles'][0]['coachAvailability'];
  venues: VenueAddress[];
  handleBookVenue: (venue: VenueAddress) => void;
}

export type CoachProps = { coach: Coach };

export type CoachPropsForForm = CoachProps & {
  isReview?: boolean;
};

export interface RegisterProps {
  setSteps: React.Dispatch<React.SetStateAction<Steps>>;
  // coach: Coach;
  // registrations?: GetUserCoachRegistrationQuery;
  // user: ReturnType<typeof useGetCurrentUser>['user'];
  handleNext: () => void;
}

export interface CurrentDateTimeState {
  date: Date;
  startTime: string;
  endTime: string;
}

export interface CurrentVenueCourtState {
  id: string;
  title: string;
  address: string;
  locationType: LocationType;
}

export interface PaymentFormProps {
  setSteps: React.Dispatch<React.SetStateAction<Steps>>;
  currentDateTime: CurrentDateTimeState;
  coach: Coach;
  setBookedLessonId: React.Dispatch<React.SetStateAction<string | null>>;
  selectedCourtVenue: CurrentVenueCourtState;
  allowBack?: boolean;
  onPaymentSuccess?: () => void;
}

export interface ResultFormProps {
  setSteps: React.Dispatch<React.SetStateAction<Steps>>;
  currentDateTime: CurrentDateTimeState;
  coach: Coach;
  bookedLessonId: string | null;
  selectedCourtVenue: CurrentVenueCourtState;
  handleBookAnotherLesson?: () => void;
}

export const EXIT_TEXT = 'No thanks, take me to the coaches';

export const ENABLE_BIRTHDAY_REQUIREMENT = false;

export interface CheckoutFormProps {
  setSteps: React.Dispatch<React.SetStateAction<Steps>>;
  currentDateTime: CurrentDateTimeState;
  coach: any;
  onSubmit: () => void;
  phoneExists?: string;
  setBookedLessonId: React.Dispatch<React.SetStateAction<string | null>>;
  selectedCourtVenue: CurrentVenueCourtState;
  allowBack?: boolean;
}

export type Lessons = GetCoachStatusAndSlotsQuery['users'][0]['coachLessons'];
